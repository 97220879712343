.nav_link{
    text-decoration: none;
    color: rgb(36, 33, 33);
    margin-left: 40px;
    cursor: pointer;
    font-weight: bold;
  }
  .nav_link2{
    text-decoration: none;
    color: rgb(36, 33, 33);
   
    cursor: pointer;
    font-weight: bold;
  }
  /* .nav_link:hover{
    color:rgb(200, 230, 130);
  } */
  .menuItem:hover{
    background: rgb(199, 185, 185);
  }